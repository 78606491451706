<template>
    <div class="m-5">
        <NavBar />
        <h1>Benutzerverwaltung</h1>
        <Card style="width: 25rem; overflow: hidden">
            <template #title>Neuen Benutzer anlegen</template>
            <template #content>
                <div>
                    <FloatLabel class="mt-4">
                        <InputText id="username" class="w-20rem" v-model="newUser.username" required />
                        <label for="username">Benutzername</label>
                    </FloatLabel>
                    <FloatLabel class="mt-4">
                        <Password id="password" class="w-20rem" v-model="newUser.password" required toggleMask
                            :inputStyle="{ width: '100%' }" />
                        <label for="password">Passwort</label>
                    </FloatLabel>
                    <FloatLabel class="mt-4">
                        <Dropdown id="role" class="w-20rem" v-model="newUser.role" :options="roles" optionLabel="label"
                            optionValue="value" required />
                        <label for="role">Rolle</label>
                    </FloatLabel>
                </div>
            </template>
            <template #footer>
                <div class="flex gap-3 mt-1">
                    <Button label="Benutzer hinzufügen" icon="pi pi-user-plus" type="submit" class="mt-2"
                        @click="addUser()" />
                </div>
            </template>
        </Card>

        <h2 class="p-mt-4">Bestehende Benutzer</h2>
        <DataTable :value="users" responsiveLayout="scroll">
            <Column field="username" header="Benutzername"></Column>
            <Column field="role" header="Rolle"></Column>
            <Column header="Aktionen">
                <template #body="{ data }">
                    <Button icon="pi pi-pencil" class="p-button-text" @click="editUser(data)" />
                    <Button icon="pi pi-trash" class="p-button-text p-button-danger"
                        @click="confirm1($event, data.id)" />
                </template>
            </Column>
        </DataTable>
        <ConfirmPopup></ConfirmPopup>

        <Dialog header="Benutzer bearbeiten" v-if="editingUser" :visible="true" modal @hide="cancelEdit">
            <form @submit.prevent="updateUser" class="p-fluid">
                <div class="p-field">
                    <label for="edit-username">Benutzername</label>
                    <InputText id="edit-username" v-model="editingUser.username" required />
                </div>
                <div class="p-field">
                    <label for="edit-password">Passwort</label>
                    <Password id="edit-password" v-model="editingUser.password" toggleMask />
                </div>
                <div class="p-field">
                    <label for="edit-role">Rolle</label>
                    <Dropdown id="edit-role" v-model="editingUser.role" :options="roles" optionLabel="label"
                        optionValue="value" required />
                </div>
                <Button label="Speichern" icon="pi pi-save" type="submit" class="mt-2" />
                <Button label="Abbrechen" icon="pi pi-times" class="p-button-secondary mt-2" @click="cancelEdit" />
            </form>
        </Dialog>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import FloatLabel from 'primevue/floatlabel';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import axios from 'axios';
import NavBar from '../components/NavBar.vue';
import { BACKEND_BASE_URL } from '@/config';

export default {
    components: {
        NavBar,
        InputText,
        Password,
        Dropdown,
        Button,
        Card,
        DataTable,
        Column,
        Dialog,
        FloatLabel,
        ConfirmPopup,
    },
    setup() {
        const confirm = useConfirm();
        return { confirm };
    },
    data() {
        return {
            users: [],
            newUser: {
                username: '',
                password: '',
                role: '',
            },
            editingUser: null,
            roles: [
                { label: 'Admin', value: 'admin' },
                { label: 'Benutzer', value: 'user' },
            ],
        };
    },
    methods: {
        getToken() {
            const token = localStorage.getItem('token');
            return token;
        },
        async fetchUsers() {
            try {
                const response = await axios.get(`${BACKEND_BASE_URL}/api/users.php`, {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                });
                this.users = response.data;
            } catch (error) {
                console.error('Fehler beim Abrufen der Benutzer:', error);
            }
        },
        async addUser() {
            try {
                await axios.post(`${BACKEND_BASE_URL}/api/users.php`, this.newUser, {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                });
                this.newUser = { username: '', password: '', role: '' };
                await this.fetchUsers();
                this.$toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Der Benutzer wurde erfolgreich hinzugefügt.', life: 3000 });
            } catch (error) {
                console.error('Fehler beim Hinzufügen des Benutzers:', error);
                this.$toast.add({ severity: 'error', summary: 'Fehler', detail: 'Beim Hinzufügen des Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.', life: 3000 });
            }
        },
        async deleteUser(id) {
            try {
                await axios.delete(`${BACKEND_BASE_URL}/api/users.php`, {
                    headers: { Authorization: `Bearer ${this.getToken()}` },
                    data: { id }
                });
                await this.fetchUsers();
            } catch (error) {
                console.error('Fehler beim Löschen des Benutzers:', error);
            }
        },
        editUser(user) {
            this.editingUser = { ...user };
        },
        async updateUser() {
            try {
                await axios.put(`${BACKEND_BASE_URL}/api/users.php`, this.editingUser, {
                    headers: { Authorization: `Bearer ${this.getToken()}` }
                });
                this.editingUser = null;
                await this.fetchUsers();
                this.$toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Der Benutzer wurde erfolgreich aktualisiert.', life: 3000 });
            } catch (error) {
                console.error('Fehler beim Aktualisieren des Benutzers:', error);
                this.$toast.add({ severity: 'error', summary: 'Fehler', detail: 'Beim Aktualisieren des Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.', life: 3000 });
            }
        },
        cancelEdit() {
            this.editingUser = null;
        },
        confirm1(event, id) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Wollen Sie den Benutzer wirklich löschen?',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
                acceptClass: 'p-button-sm p-button-danger',
                rejectLabel: 'Abbrechen',
                acceptLabel: 'Löschen',
                accept: () => {
                    this.deleteUser(id);
                },
                reject: () => {
                }
            });
        },
    },
    mounted() {
        this.fetchUsers();
    },
};
</script>

<style scoped></style>
