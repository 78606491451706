<template>
    <div id="bannerFl">
        <img class="logo-header" src="../assets/fitness_liebich_logo.png">
    </div>
</template>

<script>
export default {
    name: 'BannerFl',
    props: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#bannerFl {
    background-color: #37383e;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.logo-header {
    width: 15%;
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    .logo-header {
        width: 30%;
    }
}
</style>