import { createRouter, createWebHistory } from 'vue-router';
import CourseView from '../views/CourseView.vue';
import UserManagement from '../admin/views/UserManagement.vue';
import LoginPage from '../admin/views/LoginPage.vue';
import KursePanel from '../admin/views/KursePanel.vue';
import axios from 'axios';
import { BACKEND_BASE_URL } from '@/config';

const isAuthenticated = async (to, from, next) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    if (user && token) {
        try {
            // Anfrage zur Überprüfung des Tokens
            const response = await axios.get(`${BACKEND_BASE_URL}/api/token_validation.php`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.data.valid) {
                // Token ist gültig, Zugriff gewähren
                next();
            } else {
                console.warn('Token is not valid.', response.data);

                next({ name: 'LoginPage' });
            }
        } catch (error) {
            console.error('Fehler bei der Token-Überprüfung:', error);
            // Fehler bei der Validierung (z. B. abgelaufener Token), zur Login-Seite umleiten
            next({ name: 'LoginPage' });
        }
    } else {
        console.warn('User or token not found in localStorage.');
        next({ name: 'LoginPage' });
    }
};

const routes = [
    {
        path: '/',
        name: 'CourseView',
        component: CourseView, // Homepage
    },
    {
        path: '/admin/users',
        name: 'UserManagement',
        component: UserManagement, // UserManagement
        beforeEnter: isAuthenticated
    },
    {
        path: '/admin/login',
        name: 'LoginPage',
        component: LoginPage, // LoginPage
    },
    {
        path: '/admin/kurse',
        name: 'KursePanel',
        component: KursePanel, // KursePanel
        beforeEnter: isAuthenticated
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;