<template>
    <div class="login-container flex justify-content-center align-items-center h-30rem" style="">
        <Card overflow:hidden class="w-30rem">
            <template #title>Anmeldung</template>
            <template #content>
                <div>
                    <FloatLabel class="mt-4">
                        <InputText id="username" class="w-20rem" v-model="username" required @keyup.enter="login" />
                        <label for="username">Benutzername</label>
                    </FloatLabel>
                    <FloatLabel class="mt-4">
                        <Password id="password" class="w-20rem" v-model="password" required toggleMask :feedback="false"
                            :inputStyle="{ width: '100%' }" @keyup.enter="login" />
                        <label for="password">Passwort</label>
                    </FloatLabel>
                </div>
            </template>
            <template #footer>
                <Button label="Anmelden" icon="pi pi-sign-in" type="submit" @click="login()" />
                <Message v-if="errorMessage" severity="error">{{ errorMessage }}</Message>
            </template>
        </Card>
    </div>
</template>

<script setup>
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import FloatLabel from 'primevue/floatlabel';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Message from 'primevue/message';
import { BACKEND_BASE_URL } from '@/config';

const username = ref('');
const password = ref('');
const errorMessage = ref('');
const router = useRouter();

const login = async () => {
    try {
        const response = await axios.post(`${BACKEND_BASE_URL}/api/login.php`, {
            username: username.value,
            password: password.value,
        });

        // Speichere sowohl das Token als auch die Benutzerdaten
        localStorage.setItem('token', response.data.token); // Speichere Token
        localStorage.setItem('user', JSON.stringify({
            username: response.data.username,
            role: response.data.role,
        })); // Speichere Benutzerdaten
        console.log('Login sucessfully');

        // Leite zum Adminbereich weiter
        await router.push({ name: 'KursePanel' });
    } catch (error) {
        errorMessage.value = 'Benutzername oder Passwort ist falsch.';
        console.error('Login Fehler:', error);
    }
};
</script>

<style scoped></style>
