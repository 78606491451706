import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

// PrimeVue
import PrimeVue from 'primevue/config';
import Ripple from 'primevue/ripple';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/aura-light-teal/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

// Globale Styles
import './assets/fonts/fonts.css';

const app = createApp(App);

app.use(PrimeVue, {
    locale: {
        firstDayOfWeek: 1,
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        today: 'Heute',
        clear: 'Löschen',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'KW'
    }
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.directive('ripple', Ripple);
app.mount('#app');
