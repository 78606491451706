<template>
    <Dialog header="Homescreen-Shortcut" v-model:visible="isVisible" @hide="closeDialog">
        <p>Mit diesen 3 Schritten kannst du unsere WebApp auf deinem IPhone Homebildschirm sichern.</p>
        <Stepper orientation="vertical">
            <StepperPanel header="Inhalt Teilen">
                <template #content="{ nextCallback }">
                    <div class="flex flex-column">
                        <span>Tippe auf das Teilen-Symbol in der Menüleiste unten.</span>
                        <div class="flex justify-content-center w-100">
                            <img class="webapp_steps_img" src="@/assets/webapp_schritt_1.jpg" alt="Teilen">
                        </div>
                    </div>
                    <div class="flex py-4">
                        <Button label="Weiter" @click="nextCallback" />
                    </div>
                </template>
            </StepperPanel>
            <StepperPanel header="Zum Homebildschirm">
                <template #content="{ prevCallback, nextCallback }">
                    <div class="flex flex-column">
                        <span>Wähle <strong>„Zum Home-Bildschirm hinzufügen“</strong> aus.</span>
                        <div class="flex justify-content-center w-100">
                            <img class="webapp_steps_img" src="@/assets/webapp_schritt_2.jpg" alt="Homebildschirm">
                        </div>
                    </div>
                    <div class="flex py-4 gap-2">
                        <Button label="Zurück" severity="secondary" @click="prevCallback" />
                        <Button label="Weiter" @click="nextCallback" />
                    </div>
                </template>
            </StepperPanel>
            <StepperPanel header="Bestätigen">
                <template #content="{ prevCallback }">
                    <div class="flex flex-column">
                        <span>Bestätige durch Tippen auf „Hinzufügen“.</span>
                        <div class="flex justify-content-center w-100">
                            <img class="webapp_steps_img" src="@/assets/webapp_schritt_3.jpg" alt="Hinzufügen">
                        </div>
                    </div>
                    <div class="flex py-4">
                        <Button label="Zurück" severity="secondary" @click="prevCallback" />
                    </div>
                </template>
            </StepperPanel>
        </Stepper>

    </Dialog>
</template>

<script setup>
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
    visible: Boolean,
});

const emit = defineEmits(['update:visible']);
const isVisible = ref(props.visible);

watch(() => props.visible, (newVal) => {
    isVisible.value = newVal;
});

// Emit close event when dialog is closed
const closeDialog = () => {
    emit('update:visible', false);
};

// Update local isVisible state when prop changes
watch(() => props.visible, (newVal) => {
    isVisible.value = newVal;
});
</script>

<style scoped>
.webapp_steps_img {
    width: 30%;
    margin-top: 1rem;
}
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    .webapp_steps_img {
    width: 50%;
}
}
</style>