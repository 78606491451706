<template>
  <div class="app-container">
    <Toast/>
    <BannerFl />
    <router-view />
    <footer>
      <a href="https://impressum.fitness-liebich.de/impressum/" class="impressum-link" target="_blank" rel="noopener noreferrer" >Impressum</a>
      <img class="logo-footer" src="./assets/fl_logo_icon.png">
    </footer>
  </div>
</template>


<script setup>
</script>

<script>
import BannerFl from './components/BannerFl.vue'
import Toast from 'primevue/toast';

export default {
  name: 'App',
  components: {
    BannerFl,
    Toast,
  }
}
</script>

<style>
* {
  font-family: Prototype, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  background-color: #37383e;
  text-align: center;
  margin-top: auto;
  padding: 10px 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.impressum-link {
  position: absolute;
  left: 20px;
  top: auto;
  bottom: auto;
  color: black;
  text-decoration: none;
  margin-top: 1.5rem;
}

.impressum-link:hover {
  text-decoration: underline;
}

.logo-footer {
  width: 5%;
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  .logo-footer {
    width: 7%;
  }
}
</style>